import { Box } from "@mui/system";
import React, { useEffect, useState, memo, useCallback } from "react";
import { imageBaseURL } from "../constants/utils";
import { Button } from "@mui/material";
import ScrollToTop from "../Layouts/ScrollToTop/ScrollToTop";

const ScrollTopGlobal = memo(() => {
  const [showScroll, setShowScroll] = useState(false);

  const handleNavigation = useCallback(() => {
    const shouldShowScroll = window.scrollY !== 0;
    setShowScroll(shouldShowScroll);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return showScroll ? <ScrollToTop /> : null;
});

export default ScrollTopGlobal;
