import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  caseId: "",
  lobName: "",
  caseDescriptions: "",
  isAllocatedCase: false,
  reassignTo: "",
  index: 0,
};
export const CaseDescriptionSlice = createSlice({
  name: "Case_ID",
  initialState,
  reducers: {
    storeCaseId: (state, action) => {
      state.caseId = action?.payload?.colValue;
      state.lobName = action?.payload?.lobName;
      state.index = action?.payload?.index;
    },
    storeAllCaseDescriptions: (state, action) => {
      state.caseDescriptions = action.payload;
    },
    storeIsAllocatedCase: (state, action) => {
      state.isAllocatedCase = action.payload;
    },
    storeReAssignTo: (state, action) => {
      state.reassignTo = action.payload;
    },
  },
});

export const {
  storeCaseId,
  storeAllCaseDescriptions,
  storeIsAllocatedCase,
  storeReAssignTo,
} = CaseDescriptionSlice.actions;
export default CaseDescriptionSlice.reducer;
